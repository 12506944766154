/*

  File: video/youtube.js
  Kind: Component
  Description: Embed vimeo into a post

*/

import React from 'react'

const YouTube = (data) => {
 
  const {url, height, width, title} = data;

  //we've either got in the pattern https://youtu.be/{videoId} (short code) or https://www.youtube.com/watch?v={shortCode}
  //TODO: add playlist supprt

  const id = url.split('/').slice(-1)[0].replace('watch?v=','');
  const embed = [`https://www.youtube.com/embed`,id].join('/');

  return (
    <iframe title={title || id} src={embed} width={width} height={height} frameBorder="0" modestbranding="0"></iframe>
  );
};

export default YouTube


