/*

  File: markdown/image.jsx
  Kind: Component
  Description: Provide a Img wrapper that will also provide copyright attribution and title

*/

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

//Components

const CodeBlock = ({ path, attribution, lineNumbers }) => {
  //import sourceCode from ('./src/content/sourceCode/' + subPath);

  const data = useStaticQuery(
    graphql`
      query {
        allSourceCode {
          nodes {
            code
            language
            name
            path
          }
        }
      }
    `
  );

  //console.log(data);

  const Sources = data.allSourceCode.nodes;

  const SourceCode = Sources.find((f) => f.path === path);

  // console.log(Sources, SourceCode);

  const Attribution = attribution ? (
    <div className="font-code font-light text-sm">
      <span className="text-saturn-400">author/source: </span>
      <span className="text-neptune-200">{attribution}</span>
    </div>
  ) : (
    <div/>
  ); 


  if (SourceCode) {
    const { language, code, name } = SourceCode;
    return (
      <div className="my-4">
        <div className="flex flex-row justify-between">
          <div className="font-code font-light text-saturn-700 text-xs">code:</div>
          <div className=" text-xs">
            <span className="font-code text-saturn-400">language: </span>
            <span className="font-code text-neptune-200">{language}</span>
          </div>
        </div>
        <pre>
          <code className={`language-${language} ${lineNumbers ? 'line-numbers' : 'no-line-numbers'}`}>{code}</code>
        </pre>
        <div className="flex flex-row justify-between">
          <div className="font-code font-light text-sm">
            <span className="text-saturn-400">name: </span>
            <span className="text-neptune-200">{name}</span>
          </div>
          {Attribution}
        </div>
      </div>
    );
  } else {
    return '';
  }
};

CodeBlock.propTypes = {
  path: PropTypes.string,
  attribution: PropTypes.string,
  lineNumbers: PropTypes.bool,
};

CodeBlock.defaultProps = {
  lineNumbers: true,
};

export default CodeBlock;
