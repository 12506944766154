/*

  File: markdown/animation/embedSideBySide.jsx
  Kind: MDX Component
  Description: Show an animation (three, p5 etc) and if also passed in, show source code in files

*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';

//Components
import Title from '../../common/title';
import Attribution from '../../common/attribution';

//Utils
import ParseDimensions from '../../util/parseDimensions';
// import CodeBlock from './codeBlock';

const Alignment = {
  LEFT: 'justify-start',
  CENTRE: 'justify-center',
  RIGHT: 'justify-end',
};

const Embed = (props) => {
  const [state, setState] = useState({
    selectedTab: 0,
  });

  const { kind, attribution, title, label, width, height, alignment, arrangement, code, children } = props;

  // const Dimensions = ParseDimensions(width, height);

  const onTabClick = (e) => {
    setState({
      ...state,
      selectedTab: parseInt(e.currentTarget.dataset.id),
    });
  };

  const onTabKeyDown= (e) => {

  };

  const Header = () => {
    return '';
  };

  const Tab = ({id, text, isCurrent}) => {
    const highlightClass = isCurrent ? 'text-saturn' : 'text-neptune hover:text-saturn';
    return (
      <button className={`font-code text-sm cursor-pointer ${highlightClass} focus:outline-none`} onClick={onTabClick} onKeyDown={onTabKeyDown} data-id={id}>
        {text}
      </button>
    )
  }

  const Tabs = () => {
    //Return a list of tabs - this should be defined as follows
    // side by side - just code (if any)
    // tabbed - animation + code (if any)

    const displayName = (name) => {
      return name.split('/').slice(-1)[0];
    };

    if (code && code.length > 0) {
      const tabs = typeof code === 'string' ? [code] : code;
      if (arrangement === 'SIDE_BY_SIDE') {
        tabs.unshift(kind); //Add animation label to front of the array of tabs
      }
      const Tabs = tabs.map((m, i) => {
        return (
          <Tab key={i} id={i} text={displayName(m)} isCurrent={state.selectedTab === i}/>
        );
      });
      return (
        <div className="flex flex-col">
          <div className="text-xs font-code text-saturn-400">display:</div>
          <div className="flex flex-row justify-start my-2 space-x-2">{Tabs}</div>
        </div>
      );
    } else {
      //Return an empty div - as we weren't provided with a code reference
      return <div />;
    }
  };

  const ArrangementWrapper = ({ children }) => {
    //Return a wrapper which can hold the container - either hiding the tabs for sliding navigation - or showing side by side
    const arrangementClass = arrangement === 'SIDE_BY_SIDE' ? '' : '';
    const factor = arrangementClass === 'SIDE_BY_SIDE' ? 2 : 1;
    const wrapperDimensions = ParseDimensions(width * factor, height);
    return (
      <div className={[arrangementClass, wrapperDimensions.className].join(' ')} style={wrapperDimensions.style}>
        {children}
      </div>
    );
  };

  const Footer = () => (
    <div className="flex flex-col">
      <Title text={title} />
      <Attribution text={attribution} label={label} />
    </div>
  );

  //Return div etc if provided
  if (children) {
    //Structure is
    // main wrapper
    //  Headings and tabs
    // arrangement wrapper (if tabbed this will not display content)
    // Container for code (if any) and animation
    // Animation embed
    // Code

    return (
      <div className={`flex flex-row ${Alignment[alignment]}`}>
        <div className="flex flex-col justify-start">
          <Header />
          <Tabs />
          <ArrangementWrapper>{children}</ArrangementWrapper>
          <Footer />
        </div>
      </div>
    );
    // return (
    //   <div className={`flex flex-row ${Alignment[alignment]}`}>
    //     <div className="flex flex-col my-4 space-y-2">
    //       <div className="font-code font-light text-saturn-700 text-xs">{kind}:</div>
    //       <div className={Dimensions.className} style={Dimensions.style}>
    //         {children}
    //       </div>
    //     </div>
    //   </div>
    // );
  } else {
    return '';
  }
};

Embed.propTypes = {
  kind: PropTypes.string,
  title: PropTypes.string.isRequired,
  attribution: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignment: PropTypes.oneOf(['LEFT', 'CENTRE', 'RIGHT']),
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

Embed.defaultProps = {
  kind: 'threejs',
  alignment: 'CENTRE',
};

export default Embed;
