/*

  File: markdown/animation/embedTabbed.jsx
  Kind: MDX Component
  Description: Show an animation (three, p5 etc) and if also passed in, show source code in files

*/

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

//Components
import Title from '../../common/title';
import Attribution from '../../common/attribution';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../util/fontawesome';


//Utils
import CodeBlock from '../codeBlock';

const Alignment = {
  LEFT: 'justify-start',
  CENTRE: 'justify-center',
  RIGHT: 'justify-end',
};

const Embed = (props) => {
  // const [state, setState] = useState({
  //   selectedTab: 0,
  // });

  const { kind, attribution, title, label, width, height, alignment, code, animation } = props;

  const Dimensions = { width: width, height: height };

  const state = {
    selectedTab: 0,
  };

  const containerRef = useRef();
  const tabsRef = useRef();

  const Header = () => {
    return <div className="font-code font-light text-saturn-700 text-xs m-2">animation && source:</div>;
  };

  const Tab = ({ id, text, isCurrent }) => {
    const tabRef = useRef();

    const onTabClick = (e) => {
      // console.log(state.selectedTab, e.currentTarget.dataset.id);
      if (e.currentTarget.dataset.id !== state.selectedTab) {
        const oldTab = tabsRef.current.querySelector(`[data-id='${state.selectedTab}']`); //='${state.selectedTab}']`);
        // console.log(`[data-id='${state.selectedTab}']`, oldTab);
        state.selectedTab = e.currentTarget.dataset.id;
        containerRef.current.style.setProperty(['--tw-translate-x'], -width * parseInt(state.selectedTab) + 'px');
        tabRef.current.classList.add('text-titan');
        tabRef.current.classList.remove('text-oberon-100');
        oldTab.classList.add('text-oberon-100');
        oldTab.classList.remove('text-titan');
      }
    };

    const onTabKeyDown = (e) => {

    }

    const highlightClass = isCurrent ? 'text-titan' : 'text-oberon-100 hover:text-titan';
    return (
      <button
        ref={tabRef}
        className={`font-code font-light text-sm cursor-pointer ${highlightClass} focus:outline-none`}
        onClick={onTabClick}
        onKeyDown={onTabKeyDown}
        data-id={id}
      >
        {text}
      </button>
    );
  };

  //Create a list of tabs (not this is similar to CodeArray, but includes the threejs animation tab as well)
  const tabs = typeof code === 'string' ? [code] : code;

  const displayName = (name) => {
    return name.split('/').slice(-1)[0];
  };

  const CodeTabs = () => {
    //Return a list of tabs - this should be defined as follows
    // side by side - just code (if any)
    // tabbed - animation + code (if any)

    if (code && code.length > 0) {
      const Tabs = [kind].concat(tabs).map((m, i) => {
        return <Tab key={i} id={i} text={displayName(m)} isCurrent={state.selectedTab === i} />;
      });
      return (
        // <div className="flex flex-col">
        //   <div className="text-xs font-code text-saturn-400">display:</div>
        <div
          ref={tabsRef}
          className="flex flex-row justify-start p-2 space-x-2 bg-jupiter-100 border-b border-jupiter-200 border-solid"
          style={{ width: Dimensions.width }}
        >
          {Tabs}
        </div>
        // </div>
      );
    } else {
      //Return an empty div - as we weren't provided with a code reference
      return <div />;
    }
  };

  const EmbedWindow = ({ children }) => {
    //Return a wrapper which can hold the container - either hiding the tabs for sliding navigation - or showing side by side
    return (
      <div className="overflow-hidden" style={Dimensions}>
        {children}
      </div>
    );
  };

  const EmbedContainer = ({ animation }) => {
    //console.log(state.selectedTab, width,  state.selectedTab * width);
    const ContainerDimensions = { width: width * (tabs.length + 1), height: height, '--tw-translate-x': '0px' };
    const state = {
      fullscreen: false,
      running: true
    };
    const animationContainer = useRef();
    const play = useRef();
    const pause = useRef();
    
    const onPlayClick = () => {
      if (!state.running) {
        state.running = true; 
      }
    };

    const onPauseClick = () => {
      if (state.running) {
        state.running = false;
      }
    };
    
    const expand = useRef();
    const collapse = useRef();
    const onFullscreenClick = () => {
      if (state.fullscreen) {
        document.exitFullscreen();
        expand.current.classList.remove('hidden');
        collapse.current.classList.add('hidden');
      } else {
        animationContainer.current.requestFullscreen();
        expand.current.classList.add('hidden');
        collapse.current.classList.remove('hidden');
      }
      state.fullscreen = !state.fullscreen;
    };
    return (
      <div
        ref={containerRef}
        className="flex flex-row relative transform-gpu transition-transform duration-500"
        style={ContainerDimensions}
      >
        <div ref={animationContainer} className="" style={Dimensions}>
          {animation}
          <div className="relative bottom-8 text-oberon">
            <div className="flex flex-row justify-between mx-4">
              <div className="flex flex-row justify-start space-x-2">
                <div ref={play} className="">
                  <FontAwesomeIcon
                    icon={['far', 'play']}
                    className="text-oberon-100"
                    onClick={onPlayClick}
                  />
                </div>
                <div ref={pause} className="">
                  <FontAwesomeIcon
                    icon={['far', 'pause']}
                    className="text-oberon-600 hover:text-titania"
                    onClick={onPauseClick}
                  />
                </div>
              </div>
              <div ref={expand} className="">
                <FontAwesomeIcon
                  icon={['far', 'expand']}
                  className="text-oberon-100 hover:text-titania"
                  onClick={onFullscreenClick}
                />
              </div>
              <div ref={collapse} className="hidden">
                <FontAwesomeIcon
                  icon={['far', 'compress']}
                  className="text-oberon-100 hover:text-titania"
                  onClick={onFullscreenClick}
                />
              </div>
            </div>
          </div>
        </div>
        <Code />
      </div>
    );
  };

  const Code = () => {
    // console.log(tabs);
    const c = tabs
      .filter((f) => f !== kind)
      .map((m) => {
        // console.log(m, displayName(m), Dimensions);
        return <CodeBlock key={displayName(m)} path={m} width={Dimensions.width} height={Dimensions.height} />;
      });
    //console.log(c);
    return c;
  };

  const Footer = () => (
    <div className="flex flex-row justify-between items-center m-2 space-y-1">
      <Title text={title} />
      <Attribution text={attribution} label={label} />
    </div>
  );
  
  //Return div etc if provided
  if (animation) {
    return (
      <div className={`flex flex-row ${Alignment[alignment]}`}>
        <div className="flex flex-col justify-start">
          <Header />
          <CodeTabs />
          <EmbedWindow>
            <EmbedContainer animation={animation} />
          </EmbedWindow>
          <Footer />
        </div>
      </div>
    );
  } else {
    return '';
  }
};

Embed.propTypes = {
  kind: PropTypes.string,
  title: PropTypes.string.isRequired,
  attribution: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alignment: PropTypes.oneOf(['LEFT', 'CENTRE', 'RIGHT']),
  animation: PropTypes.object.isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

Embed.defaultProps = {
  kind: 'threejs',
  alignment: 'CENTRE',
};

export default Embed;
