import React, { useRef, useEffect, useState } from 'react';

//threejs
import * as THREE from 'three';
// import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

//react-three-fiber
import { Canvas, useFrame } from 'react-three-fiber';
import { Stats, Center, OrbitControls } from '@react-three/drei';

//DatGui
import DatGui, { DatBoolean, DatColor, DatNumber, DatFolder } from 'react-dat-gui';

//Harmonograph Components
import Rotor from '../../../../src/threejs/components/rotor';

let f = 0;

const MeshWithHoles = (props) => {
  const { width, height, sizeX, sizeY, color, indices, rotation, position } = props;

  //construct vertices - use a unit size of 1 - these are always the same, we calculate indices based on hole style / shape
  const nv = (width + 1) * (height + 1);
  const vertices = new Float32Array(nv * 3);

  for (let i = 0; i < nv; i++) {
    const i_ = i * 3;
    vertices[i_] = i % (width + 1);
    vertices[i_ + 1] = Math.floor(i / (width + 1));
    vertices[i_ + 2] = 0;
    // console.log('VRT:',i,'BASE:', i_, 'VTX:',vertices[i_], vertices[i_ + 1], vertices[i_ + 2]);
  }

  return (
    <mesh scale={[sizeX, sizeY, 1]} rotation={rotation} position={position}>
      <bufferGeometry attach="geometry">
        <bufferAttribute attach="index" array={indices} count={indices.length} itemSize={1} />
        <bufferAttribute
          attachObject={['attributes', 'position']}
          array={vertices}
          count={vertices.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <meshPhongMaterial color={color} side={THREE.DoubleSide} opacity={0.6} />
    </mesh>
  );
};

const MeshWithTriangleHoles = (props) => {
  const { width, height } = props;

  //construct indices
  const nt = width * height;
  const indices = new Uint16Array(nt * 3);

  for (let i = 0; i < nt; i++) {
    const i_ = i * 3;
    const _i = i + Math.floor(i / width);
    indices[i_] = _i;
    indices[i_ + 1] = _i + 1;
    indices[i_ + 2] = _i + width + 1;
    // console.log('IDX:',i,'BASE:', _i, 'VTX:',indices[i_], indices[i_ + 1], indices[i_ + 2]);
  }

  return <MeshWithHoles indices={indices} {...props} />;
};

const MeshWithDiamondHoles = (props) => {
  const { width, height } = props;

  //construct indices
  const nt = width * height;
  const indices = new Uint16Array(nt * 3);

  for (let i = 0; i < nt; i++) {
    const i_ = i * 3;
    const _i = i + Math.floor(i / width);

    const a = (_i % 2) * 2 + (Math.floor(i / width) % 2);

    switch (a) {
      case 0:
        indices[i_] = _i;
        indices[i_ + 1] = _i + 1;
        indices[i_ + 2] = _i + width + 1;
        break;
      case 1:
        indices[i_] = _i + 1;
        indices[i_ + 1] = _i + width + 1;
        indices[i_ + 2] = _i + width + 2;
        break;
      case 2:
        indices[i_] = _i;
        indices[i_ + 1] = _i + 1;
        indices[i_ + 2] = _i + width + 2;
        break;
      case 3:
        indices[i_] = _i;
        indices[i_ + 1] = _i + width + 1;
        indices[i_ + 2] = _i + width + 2;
        break;
    }

    // console.log('IDX:',i,'AlG:', a, 'BASE:', _i, 'VTX:',indices[i_], indices[i_ + 1], indices[i_ + 2]);
  }

  return <MeshWithHoles indices={indices} {...props} />;
};

const MeshWithSquareHoles = (props) => {
  const { width, height } = props;

  //construct indices
  const nh = Math.floor(width / 2) * Math.floor(height / 2);
  const nt = 2 * width * height - 2 * nh;
  const nv = (width + 1) * (height + 1);
  const indices = new Uint16Array(nt * 3);

  // console.log(nt, nv);

  //Use a nested width / height loop instead - then we can better check x/y coords!

  let i = 0;
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const t = y % 2 === 0 || (y % 2 > 0 && x % 2 === 0);
      if (t) {
        const _i = y * (width + 1) + x;
        //create two triangles
        indices[i++] = _i;
        indices[i++] = _i + width + 1;
        indices[i++] = _i + width + 2;
        // console.log('added triangle at x:', x, 'y:', y, '_i:', _i, 'v:', _i, _i + width + 1, _i + width + 2);
        //second one
        indices[i++] = _i;
        indices[i++] = _i + 1;
        indices[i++] = _i + width + 2;
        // console.log('added triangle at x:', x, 'y:', y, '_i:', _i, 'v:', _i, _i + 1, _i + width + 2);
      }
    }
  }
  return <MeshWithHoles indices={indices} {...props} />;
};

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={7} />
      <spotLight
        castShadow
        intensity={10}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

// const value = 0.0127;

const TriangularHoles = (props) => {
  const statsRef = useRef();
  const theta = (Math.PI * 2) / 3;

  const [rotorStates, setRotorStates] = useState({
    system1: {
      spin: {
        x: 0,
        y: 0,
        z: 0.23,
      },
      visible: {
        rotor: false,
      },
    },
    system2: {
      spin: {
        x: 0,
        y: 0,
        z: -0.35,
      },
      visible: {
        rotor: false,
      },
    },
    system3: {
      spin: {
        x: 0,
        y: 0.035,
        z: -0.01,
      },
      visible: {
        rotor: false,
      },
    },
  });

  const handleUpdate = (newData) => {
    setRotorStates(newData);
  };

  return (
    <div ref={statsRef} id="StatsParent" className="h-full">
      <Canvas style={{ backgroundColor: '#4682b4' }} camera={{ zoom: 1, position: [0, 0, 100] }}>
        {/* <Stats parent={statsRef}/> */}
        <OrbitControls />
        <Lights />
        {/* <axesHelper args={[75]} /> */}
        <Rotor
          position={[0, 0, -150]}
          radius={50}
          size={2}
          spin={rotorStates.system1.spin}
          visible={rotorStates.system1.visible}
        >
          <MeshWithTriangleHoles
            rotation={[0, 0, 0]}
            position={[0, 50, 0]}
            color={0x4b0082}
            width={50}
            height={50}
            sizeX={3}
            sizeY={3}
          />
          <Rotor
            position={[5, 0, -100]}
            rotation={[0, Math.PI / 2, 0]}
            radius={20}
            size={2}
            spin={rotorStates.system2.spin}
            visible={rotorStates.system2.visible}
          >
            <MeshWithDiamondHoles
              rotation={[0, 0, 0]}
              position={[0, 20, 0]}
              color={0xf0f8ff}
              width={50}
              height={60}
              sizeX={0.3}
              sizeY={0.5}
            />
            <Rotor
              position={[-60, 25, -250]}
              radius={80}
              size={2}
              spin={rotorStates.system3.spin}
              visible={rotorStates.system3.visible}
            >
              <MeshWithSquareHoles
                rotation={[0, Math.PI / 2, 0]}
                position={[80, 0, 0]}
                color={0x663399}
                width={120}
                height={120}
                sizeX={1}
                sizeY={1}
              />
            </Rotor>
          </Rotor>
        </Rotor>
        <Rotor
          position={[20, 5, -140]}
          rotation={[0, Math.PI / 2, 0]}
          radius={20}
          size={2}
          spin={rotorStates.system2.spin}
          visible={rotorStates.system2.visible}
        >
          <MeshWithDiamondHoles
            rotation={[0, 0, 0]}
            position={[0, 20, 0]}
            color={0xf0f8ff}
            width={50}
            height={60}
            sizeX={2}
            sizeY={2}
          />
        </Rotor>
        <Rotor
          position={[-20, -15, -160]}
          radius={80}
          size={2}
          spin={rotorStates.system3.spin}
          visible={rotorStates.system3.visible}
        >
          <MeshWithSquareHoles
            rotation={[0, Math.PI / 2, 0]}
            position={[80, 0, 0]}
            color={0x663399}
            width={100}
            height={100}
            sizeX={1}
            sizeY={1}
          />
        </Rotor>
        <Rotor
          position={[0, 30, -200]}
          radius={50}
          size={2}
          spin={rotorStates.system1.spin}
          visible={rotorStates.system1.visible}
        >
          <MeshWithTriangleHoles
            rotation={[0, 0, 0]}
            position={[0, 50, 0]}
            color={0x4b0082}
            width={250}
            height={250}
            sizeX={0.1}
            sizeY={0.75}
          />
        </Rotor>

        {/* <Rotor radius={50} size={2} position={[-30, 0, 0]} rotation={[theta, 0, -theta]}>
          <MeshCubeWithHoles width={100} height={100} sizeX={0.3} sizeY={0.3} pos={[25, 0, 0]} />
          <MeshCubeWithHoles
            width={100}
            height={100}
            sizeX={0.3}
            sizeY={0.3}
            pos={[Math.cos(theta) * 25, Math.sin(theta) * 25, 0]}
          />
          <MeshCubeWithHoles
            width={100}
            height={100}
            sizeX={0.3}
            sizeY={0.3}
            pos={[Math.cos(theta * 2) * 25, Math.sin(theta * 2) * 25, 0]}
          />
        </Rotor>
        <Rotor radius={50} size={2} position={[30, 0, 0]} rotation={[0, theta / 2, -theta]}>
          <MeshCubeWithHoles width={120} height={120} sizeX={0.2} sizeY={0.2} pos={[25, 0, 0]} />
          <MeshCubeWithHoles
            width={120}
            height={120}
            sizeX={0.2}
            sizeY={0.2}
            pos={[Math.cos(theta) * 25, Math.sin(theta) * 25, 0]}
          />
          <MeshCubeWithHoles
            width={120}
            height={120}
            sizeX={0.2}
            sizeY={0.2}
            pos={[Math.cos(theta * 2) * 25, Math.sin(theta * 2) * 25, 0]}
          />
        </Rotor> */}
      </Canvas>
      <div id="triangular-holes-gui" style={{ position: 'absolute', top: 0, left: 0 }}>
        <DatGui data={rotorStates} onUpdate={handleUpdate}>
          <DatFolder title="system1" closed={false}>
            <DatNumber path="system1.spin.x" label="spin.x" min={-1} max={1} step={0.001} />
            <DatNumber path="system1.spin.y" label="spin.y" min={-1} max={1} step={0.001} />
            <DatNumber path="system1.spin.z" label="spin.z" min={-1} max={1} step={0.001} />
            <DatBoolean path="system1.visible.rotor" label="visible.rotor" />
          </DatFolder>
          <DatFolder title="system2" closed={false}>
            <DatNumber path="system2.spin.x" label="spin.x" min={-1} max={1} step={0.001} />
            <DatNumber path="system2.spin.y" label="spin.y" min={-1} max={1} step={0.001} />
            <DatNumber path="system2.spin.z" label="spin.z" min={-1} max={1} step={0.001} />
            <DatBoolean path="system2.visible.rotor" label="visible.rotor" />
          </DatFolder>
          <DatFolder title="system3" closed={false}>
            <DatNumber path="system3.spin.x" label="spin.x" min={-1} max={1} step={0.001} />
            <DatNumber path="system3.spin.y" label="spin.y" min={-1} max={1} step={0.001} />
            <DatNumber path="system3.spin.z" label="spin.z" min={-1} max={1} step={0.001} />
            <DatBoolean path="system3.visible.rotor" label="visible.rotor" />
          </DatFolder>
        </DatGui>
      </div>
    </div>
  );
};

export default TriangularHoles;
