/*

  File: common/title.jsx
  Kind: Component
  Description: Title for images, source code etc

*/

import * as React from 'react';
import PropTypes from 'prop-types';


const Title = ({text}) => {
  //Set attribution if provided
  return (
    <div className="font-code text-jupiter-400 text-xs">
        {text}
      </div>
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
};


export default Title;