/*

  File: video/vimeo.js
  Kind: Component
  Description: Embed vimeo into a post

*/

import React from 'react';

const Vimeo = (data) => {

  const {url, height, width, title} = data;
  //Assume we got url as pattern https://vimeo.com/{videoId} and that we are embedding as https://player.vimeo.com/video/{videoId}
  const id = url.replace('https://vimeo.com/', '');
  const embed = [`https://player.vimeo.com/video`,id].join('/');

  return (
    <iframe title={title || id} src={embed} width={width} height={height} frameBorder="0" allow="fullscreen; picture-in-picture" allowFullScreen={true}></iframe>
  );
};

export default Vimeo;
