/*

  File: singleRotor.jsx
  Kind: ThreeJS canvas
  Description: Example of a single rotor in action, with dat gui controls

*/

import * as THREE from 'three';
import React, { useRef, } from 'react';
import { Canvas, extend, useFrame } from 'react-three-fiber';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OrbitControls, Effects } from '@react-three/drei';


extend({ UnrealBloomPass });

const Rotors = ({ radius1, size1, radius2, size2, radius3, size3, trail, trailInterval }) => {
  const rotor1 = useRef();
  const rotor2 = useRef();
  const rotor3 = useRef();

  const ball0 = useRef();
  const ball1 = useRef();
  const ball2 = useRef();

  const ball3 = useRef();
  const ball4 = useRef();
  const ball5 = useRef();

  const ball6 = useRef();
  const ball7 = useRef();
  const ball8 = useRef();

  const rotation = [0, 0, 0];


  const trail0 = useRef();
  const trail1 = useRef();
  const trail2 = useRef();

  const trail3 = useRef();
  const trail4 = useRef();
  const trail5 = useRef();

  const trail6 = useRef();
  const trail7 = useRef();
  const trail8 = useRef();

  const trails = [
    {
      id: 0,
      sourceRef: ball0,
      targetRef: trail0,
      pos: []
    },
    {
      id: 1,
      sourceRef: ball1,
      targetRef: trail1,
      pos: []
    },
    {
      id: 2,
      sourceRef: ball2,
      targetRef: trail2,
      pos: []
    },
    {
      id: 3,
      sourceRef: ball3,
      targetRef: trail3,
      pos: []
    },
    {
      id: 4,
      sourceRef: ball4,
      targetRef: trail4,
      pos: []
    },
    {
      id: 5,
      sourceRef: ball5,
      targetRef: trail5,
      pos: []
    },
    {
      id: 6,
      sourceRef: ball6,
      targetRef: trail6,
      pos: []
    },
    {
      id: 7,
      sourceRef: ball7,
      targetRef: trail7,
      pos: []
    },
    {
      id: 8,
      sourceRef: ball8,
      targetRef: trail8,
      pos: []
    },
  ]


  const tempObject = new THREE.Object3D();
  const trailPosLength = trail * trailInterval


  const origin = new THREE.Vector3(0,0,0);

  useFrame(() => {
    if (rotor1.current) {
      rotor1.current.rotation.x += (0.0321 + (Math.random() * 0.1)) / 6;
      rotor1.current.rotation.y += (0.00201 + (Math.random() * 0.1)) / 5.3;
      rotor1.current.rotation.z += (0.0797 + (Math.random() * 0.1))  / 4.9;
    }
    if (rotor2.current) {
      rotor2.current.rotation.x += 0.000321;
      rotor2.current.rotation.y += 0.0201;
      rotor2.current.rotation.z -= 0.0597;
    }
    if (rotor3.current) {
      rotor3.current.rotation.x += 0.10321;
      rotor3.current.rotation.y += 0.00099;
      rotor3.current.rotation.z -= 0.00006;
    }

    for (let i=0; i<trails.length; i++) {
      if (trails[i].sourceRef.current) {
        const worldPos = trails[i].sourceRef.current.getWorldPosition(origin);
        trails[i].pos.unshift(new THREE.Vector3(worldPos.x, worldPos.y, worldPos.z)); //record current position
        if (trails[i].pos.length > trailPosLength) {
          trails[i].pos.splice(trailPosLength);    //push last value off the end
        }  
      }
      for (let j=0; j<trail; j++) {
        const j_ = ((j+1) * trailInterval);
        let scale = 0;
        if (trails[i].pos.length > j_) {
          scale = Math.max((1 - (j * 0.09)),0.0); 
          tempObject.position.set(trails[i].pos[j_].x, trails[i].pos[j_].y, trails[i].pos[j_].z);
        }
        tempObject.scale.set(scale, scale, scale);
        tempObject.updateMatrix();
        trails[i].targetRef.current.setMatrixAt(j, tempObject.matrix);
      }
      trails[i].targetRef.current.instanceMatrix.needsUpdate = true;
    }
  });

  const ringRadius = radius1 + size1 / 2;
  const theta = (2 * Math.PI) / 3;

  const ringRadius2 = radius2 + size2 / 2;
  const ringRadius3 = radius3 + size3 / 2;

  return (
    <group>
      <group ref={rotor1} rotation={rotation}>
        <mesh visible={false}>
          <torusGeometry args={[radius1, size1 / 4, 3, 32]} />
          <meshBasicMaterial color="#999999" wireframe />
        </mesh>
        <mesh ref={trails[6].sourceRef} position={[ringRadius, 0, 0]}>
          <sphereGeometry args={[size2, 32, 32]} />
          <meshBasicMaterial color="#8800FF" wireframe />
        </mesh>
        <mesh ref={trails[7].sourceRef} position={[Math.cos(theta) * ringRadius, Math.sin(theta) * ringRadius, 0]}>
          <sphereGeometry args={[size2, 32, 32]} />
          <meshBasicMaterial color="#00FF88" wireframe />
        </mesh>
        <mesh ref={trails[8].sourceRef} position={[Math.cos(2 * theta) * ringRadius, Math.sin(2 * theta) * ringRadius, 0]}>
          <sphereGeometry args={[size2, 32, 32]} />
          <meshBasicMaterial color="#FF8800" wireframe />
        </mesh>
        <group ref={rotor2} rotation={rotation} position={[ringRadius, 0, 0]}>
          <mesh visible={false}>
            <torusGeometry args={[radius2, size2 / 4, 3, 32]} />
            <meshBasicMaterial color="#999999" wireframe />
          </mesh>
          <mesh ref={trails[3].sourceRef} position={[ringRadius2, 0, 0]}>
            <sphereGeometry args={[size2, 32, 32]} />
            <meshBasicMaterial color="#0000FF" wireframe />
          </mesh>
          <mesh ref={trails[4].sourceRef} position={[Math.cos(theta) * ringRadius2, Math.sin(theta) * ringRadius2, 0]}>
            <sphereGeometry args={[size2, 32, 32]} />
            <meshBasicMaterial color="#00FF00" wireframe />
          </mesh>
          <mesh ref={trails[5].sourceRef} position={[Math.cos(2 * theta) * ringRadius2, Math.sin(2 * theta) * ringRadius2, 0]}>
            <sphereGeometry args={[size2, 32, 32]} />
            <meshBasicMaterial color="#FF0000" wireframe />
          </mesh>
          <group ref={rotor3} rotation={rotation} position={[ringRadius2, 0, 0]}>
            <mesh visible={false}>
              <torusGeometry args={[radius3, size3 / 4, 3, 32]} />
              <meshBasicMaterial color="#999999" wireframe />
            </mesh>
            <mesh ref={trails[0].sourceRef} position={[ringRadius3, 0, 0]}>
              <sphereGeometry args={[size3, 32, 32]} />
              <meshBasicMaterial color="#FF00FF" wireframe />
            </mesh>
            <mesh ref={trails[1].sourceRef} position={[Math.cos(theta) * ringRadius3, Math.sin(theta) * ringRadius3, 0]}>
              <sphereGeometry args={[size3, 32, 32]} />
              <meshBasicMaterial color="#00FFFF" wireframe />
            </mesh>
            <mesh ref={trails[2].sourceRef} position={[Math.cos(2 * theta) * ringRadius3, Math.sin(2 * theta) * ringRadius3, 0]}>
              <sphereGeometry args={[size3, 32, 32]} />
              <meshBasicMaterial color="#FFFF00" wireframe />
            </mesh>
          </group>
        </group>
      </group>
      <instancedMesh ref={trails[0].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#FF00FF" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[1].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#00FFFF" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[2].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#FFFF00" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[3].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#0000FF" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[4].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#00FF00" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[5].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#FF0000" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[6].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#8800FF" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[7].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#00FF88" wireframe/>
      </instancedMesh>
      <instancedMesh ref={trails[8].targetRef} args={[null, null, trail]}>
        <sphereGeometry attach="geometry" args={[size3, 32, 32]} />
        <meshBasicMaterial color="#FF8800" wireframe/>
      </instancedMesh>
    </group>
  );
};


function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={10} />
      <spotLight
        castShadow
        intensity={0.2}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}


const SphericalTrails = () => {
  
  return (
    <Canvas
      shadowMap
      style={{ backgroundColor: '#000021' }}
      camera={{ position: [10, 20, 140], fov: 80 }}
      data-id="sphericalTrails"
    >
      <OrbitControls />
      <Lights />
      {/* <Axes length={75} /> */}
      <Rotors radius1={50} size1={2} radius2={30} size2={2} radius3={40} size3={2} trail={12} trailInterval={4} />
      <Effects>
        <unrealBloomPass attachArray="passes" args={[undefined, 2.1, 0.1, 0.1]} />
      </Effects>


    </Canvas>
  );
}

export default SphericalTrails;