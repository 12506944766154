/*

  File: common/attribution.jsx
  Kind: Component
  Description: Attribution label for images etc

*/

import * as React from 'react';
import PropTypes from 'prop-types';

import Label from './label'

const Attribution = (props) => {

  const { label, text } = props;

  //Set attribution if provided
  if (text) {
    return (
      <div className="font-code font-light text-sm">
      <Label text={label}/>
      <span className="text-neptune-200 pl-2">{text}</span>
    </div>
    )
  } else {
    //return an empty div so we don't mess layout up
    return (
      <div/>
    )
  }
}

Attribution.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string
};


export default Attribution;