/*

  File: markdown/image.jsx
  Kind: Component
  Description: Provide a Img wrapper that will also provide copyright attribution and title

*/

import * as React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

//Components

const Image = ({ img, title, copyright, width }) => {
  
  console.log('IMG: ', img);

  const Sub = (title || copyright) ? (
    <div className="flex flex-row justify-between my-4">
      <div className="font-code text-jupiter-400 text-xs">
        {title}
      </div>
      <div className="font-code text-jupiter-400 text-xs">
        {copyright}
      </div>
    </div>
  ) : '';
  
  if (img) {
    return (
      <div>
        <div className="font-code text-saturn-700 text-xs mb-2">image:</div>
        <Link to={img.publicURL}>
          <GatsbyImage image={img.childImageSharp.gatsbyImageData} className={`${width}`} alt={title || ''}/>
        </Link>
        {Sub}
      </div>
    );
  } else {
    return (
      <div className="font-code text-neptune">
        !!Invalid property passed to Image tag!!
      </div>
    )
  }

  
};


Image.propTypes = {
  img: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  copyright: PropTypes.string,
  width: PropTypes.string
};

Image.defaultProps = {
  width: 'w-full'
};

export default Image;
