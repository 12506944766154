/*

  File: rotorWithPlane.jsx
  Kind: ThreeJS canvas
  Description: Example of a nested rotors, with a plane attached to the final rotor.

*/

import React, { useRef, Suspense } from 'react';
import PropTypes from 'prop-types';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { Canvas, extend, useFrame, useThree, useLoader } from 'react-three-fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';

extend({ OrbitControls });

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls component.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  const {
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame((state) => controls.current.update());
  return <orbitControls ref={controls} args={[camera, domElement]} />;
};

// Rotor as component
const Rotor = ({ position, rotation, spin, size, children, visible }) => {
  const rotor = useRef();

  useFrame(() => {
    rotor.current.rotation.x += spin[0] / 3;
    rotor.current.rotation.y += spin[1] / 3;
    rotor.current.rotation.z += spin[2] / 2;
  });

  return (
    <group ref={rotor} rotation={rotation} position={position}>
      <mesh visible={visible}>
        <torusGeometry args={[size, 0.5, 4, 32]} />
        <meshBasicMaterial color={0x666666} wireframe />
      </mesh>
      {children}
    </group>
  );
};


const TexturePlane = ({ position, rotation, color, isBillboard }) => {
  const texture = useLoader(TextureLoader, '/threejs/textures/PlaneTexture.png');

  const plane = useRef();

  useFrame(({ camera }) => {
    //ensure the plane is always pointing towards the camera
    plane.current.lookAt(camera.position);
    //plane.current.quaternion.copy( camera.quaternion );
  });

  return (
    <mesh ref={plane} position={position} rotation={rotation}>
      <planeBufferGeometry attach="geometry" args={[60, 60, 1, 1]} />
      <meshStandardMaterial map={texture} attach="material" side={THREE.DoubleSide} />
    </mesh>
  );
};

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={1} />
      <spotLight
        castShadow
        intensity={0.1}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

const TubeTrails = ({ running }) => {
  return (
    <Canvas shadowMap style={{ backgroundColor: '#000021' }} orthographic camera={{ position: [10, 20, 80], fov: 80 }}>
      {/* <axesHelper args={[75]} /> */}
      <CameraControls />
      <Lights />
      <Rotor id="r1" position={[0, 20, 0]} rotation={[0, 0, 0]} spin={[0.000123, 0.00067, 0.01]} size={50} visible>
        <Rotor id="r2" position={[0, 50, 0]} rotation={[0, 0, 0]} spin={[0.01, -0.0001, 0.0775]} size={40} visible>
          <Rotor id="r3" position={[40, 0, 0]} rotation={[0, 0, 0]} spin={[-0.021, 0.075, 0.1275]} size={30} visible>
            <Suspense fallback={null}>
              <TexturePlane position={[Math.cos(0) * 30, Math.sin(0) * 30, 0]} rotation={[90, 0, 0]} color={0x0089fe} />
            </Suspense>
          </Rotor>
        </Rotor>
      </Rotor>
    </Canvas>
  );
};

TubeTrails.propTypes = {
  running: PropTypes.bool,
};

TubeTrails.defaultProps = {
  running: true,
};

export default TubeTrails;
