import React, { useRef } from 'react';
import PropTypes from 'prop-types';
//import * as THREE from 'three';
import { useFrame } from 'react-three-fiber';



//Spin conversion - input is normalised -1.0 > 1.0 - we want to convert into a sensible figure of radians per second (as dT from useFrame will give us this in seconds)
const getSpin = (a, dT) => {

  const LIMIT = 5;
  return a * LIMIT * dT;

}


const Rotor = (props) => {
  const { position, rotation, spin, radius, size, visible, children } = props;

  const rotor = useRef();
  const torus = useRef();
  // const rotation = [0, 0, 0];
  useFrame((state, dT) => {
    // console.log(dT);

    if (rotor.current) {
      rotor.current.rotation.x += getSpin(spin.x,dT);
      rotor.current.rotation.y += getSpin(spin.y,dT);
      rotor.current.rotation.z += getSpin(spin.z,dT);
    }
    torus.current.visible = visible.rotor;
  });


  const ringRadius = radius + size / 2;

  return (
    <group ref={rotor} rotation={rotation} position={position}>
      <mesh ref={torus} visible={visible.rotor}>
        <torusGeometry args={[ringRadius, 1, 8, 32]} />
        <meshBasicMaterial color="#999999" wireframe />
      </mesh>
      {children}
    </group>
  );
};

Rotor.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number),
  rotation: PropTypes.arrayOf(PropTypes.number),
  spin: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number
  }),
  radius: PropTypes.number.isRequired,
  size: PropTypes.number,
  visible: PropTypes.shape({
    rotor: PropTypes.bool
  })
}

Rotor.defaultProps = {
  spin: { x: 0, y:0, z:0 },
  size: 0.5,
  visible: {
    rotor: false
  }
}

export default Rotor;