/*

  File: tube.jsx
  Kind: ThreeJS canvas
  Description: Example of a single rotor in action, with dat gui controls

*/

import React, { useRef } from 'react';

import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OrbitControls, Effects } from '@react-three/drei';

import { Canvas, extend, useFrame } from 'react-three-fiber';

extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass });

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={10} />
      <spotLight
        castShadow
        intensity={0.2}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

const Tube = () => {
  //const path = new CustomSinCurve( 100 );

  const initPoints = [];
  for (let i = 0; i < 20; i++) {
    const th = ((Math.PI * 2) / 10) * i;
    const r = i;
    const z = i * 2.5;
    initPoints.push(new THREE.Vector3(Math.cos(th) * r, Math.sin(th) * r, z));
  }

  const curve = new THREE.CatmullRomCurve3(initPoints);

  const tube = useRef();

  // let first = true;

  useFrame(() => {
    for (let i = 0; i < 10; i++) {
      initPoints[i].x += (Math.random() - 0.5) * 0.3;
      initPoints[i].y += (Math.random() - 0.5) * 0.2;
    }
    const tubeG = new THREE.TubeGeometry(curve, 100, 4, 12);
    tube.current.geometry.setAttribute('position', tubeG.getAttribute('position'));
    tube.current.geometry.attributes.position.needsUpdate = true;
  });

  const tubeGeometry = new THREE.TubeGeometry(curve, 100, 4, 12);

  const indices = tubeGeometry.getIndex();
  const vertices = tubeGeometry.getAttribute('position');

  return (
    <mesh ref={tube}>
      <bufferGeometry attach="geometry">
        <bufferAttribute attach="index" array={indices.array} count={indices.array.length} itemSize={1} />
        <bufferAttribute
          attachObject={['attributes', 'position']}
          array={vertices.array}
          count={vertices.array.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <meshBasicMaterial color="#0089FE" wireframe />
    </mesh>
  );
};

const TubeTrails = ({ running }) => {
  

  return (
    <>
      <Canvas shadowMap style={{ backgroundColor: '#000021' }} camera={{ position: [10, 20, 140], fov: 80 }}>
        {/* <axesHelper args={[75]} /> */}
        <OrbitControls />
        <Lights />
        <Tube />
        <Effects>
          <renderPass attachArray="passes"/>
          <unrealBloomPass attachArray="passes" args={[undefined, 1.1, 0.25, 0]} />
        </Effects>
      </Canvas>
      
    </>
  );
};

export default TubeTrails;
