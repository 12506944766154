/*

  File: nestedRotor.jsx
  Kind: ThreeJS canvas
  Description: Example of a nested rotors

*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';

const Rotors = ({ radius1, size1, radius2, size2, radius3, size3, trail, trailInterval }) => {
  const rotor1 = useRef();
  const rotor2 = useRef();
  const rotor3 = useRef();

  const rotation = [0, 0, 0];

  useFrame(() => {
    if (rotor1.current) {
      rotor1.current.rotation.x += 0.0321  / 6;
      rotor1.current.rotation.y += 0.00201  / 5.3;
      rotor1.current.rotation.z += 0.0797   / 4.9;
    }
    if (rotor2.current) {
      rotor2.current.rotation.x += 0.000321;
      rotor2.current.rotation.y += 0.0201;
      rotor2.current.rotation.z -= 0.0597;
    }
    if (rotor3.current) {
      rotor3.current.rotation.x += 0.10321 / 3;
      rotor3.current.rotation.y += 0.00099;
      rotor3.current.rotation.z -= 0.00006;
    }


    
  });

  const ringRadius = radius1 + size1 / 2;
  const theta = (2 * Math.PI) / 3;

  const ringRadius2 = radius2 + size2 / 2;
  const ringRadius3 = radius3 + size3 / 2;

  return (
    <group>
      <group ref={rotor1} rotation={rotation}>
        <mesh visible={true}>
          <torusGeometry args={[ringRadius, 0.5, 8, 32]} />
          <meshBasicMaterial color="#999999" wireframe side={THREE.DoubleSide} />
        </mesh>
        <mesh  position={[ringRadius, 0, 0]}>
          <sphereGeometry args={[size2, 32, 32]} />
          <meshBasicMaterial color="#8800FF" wireframe side={THREE.DoubleSide}/>
        </mesh>
        <mesh  position={[Math.cos(theta) * ringRadius, Math.sin(theta) * ringRadius, 0]}>
          <sphereGeometry args={[size2, 32, 32]} />
          <meshBasicMaterial color="#00FF88" wireframe side={THREE.DoubleSide}/>
        </mesh>
        <mesh position={[Math.cos(2 * theta) * ringRadius, Math.sin(2 * theta) * ringRadius, 0]}>
          <sphereGeometry args={[size2, 32, 32]} />
          <meshBasicMaterial color="#FF8800" wireframe side={THREE.DoubleSide}/>
        </mesh>
        <group ref={rotor2} rotation={rotation} position={[ringRadius, 0, 0]}>
          <mesh visible={true}>
          <torusGeometry args={[ringRadius2, 0.5, 8, 32]} />
            <meshBasicMaterial color="#999999" wireframe side={THREE.DoubleSide} />
          </mesh>
          <mesh  position={[ringRadius2, 0, 0]}>
            <sphereGeometry args={[size2, 32, 32]} />
            <meshBasicMaterial color="#0000FF" wireframe side={THREE.DoubleSide}/>
          </mesh>
          <mesh  position={[Math.cos(theta) * ringRadius2, Math.sin(theta) * ringRadius2, 0]}>
            <sphereGeometry args={[size2, 32, 32]} />
            <meshBasicMaterial color="#00FF00" wireframe />
          </mesh>
          <mesh  position={[Math.cos(2 * theta) * ringRadius2, Math.sin(2 * theta) * ringRadius2, 0]}>
            <sphereGeometry args={[size2, 32, 32]} />
            <meshBasicMaterial color="#FF0000" wireframe side={THREE.DoubleSide}/>
          </mesh>
          <group ref={rotor3} rotation={rotation} position={[ringRadius2, 0, 0]}>
            <mesh visible={true}>
            <torusGeometry args={[ringRadius3, 0.5, 8, 32]} />
              <meshBasicMaterial color="#999999" wireframe side={THREE.DoubleSide} />
            </mesh>
            <mesh  position={[ringRadius3, 0, 0]}>
              <sphereGeometry args={[size3, 32, 32]} />
              <meshBasicMaterial color="#FF00FF" wireframe side={THREE.DoubleSide}/>
            </mesh>
            <mesh position={[Math.cos(theta) * ringRadius3, Math.sin(theta) * ringRadius3, 0]}>
              <sphereGeometry args={[size3, 32, 32]} />
              <meshBasicMaterial color="#00FFFF" wireframe side={THREE.DoubleSide}/>
            </mesh>
            <mesh  position={[Math.cos(2 * theta) * ringRadius3, Math.sin(2 * theta) * ringRadius3, 0]}>
              <sphereGeometry args={[size3, 32, 32]} />
              <meshBasicMaterial color="#FFFF00" wireframe side={THREE.DoubleSide}/>
            </mesh>
          </group>
        </group>
      </group>
    </group>
  );
};

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={10} />
      <spotLight
        castShadow
        intensity={0.2}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

const NestedRotor = () => {
  return (
    <Canvas
      style={{ backgroundColor: '#000021' }}
      orthographic
      camera={{ zoom: 3, position: [10, 20, 50] }}
    >
      <OrbitControls />
      <Lights />
      <Rotors radius1={50} size1={2} radius2={30} size2={2} radius3={40} size3={2} trail={15} trailInterval={7} />
      <axesHelper args={[75]} />
    </Canvas>
  );
}

export default NestedRotor;