/*

  File: singleRotor.jsx
  Kind: ThreeJS canvas
  Description: Example of a single rotor in action, with dat gui controls

*/

import React, { useRef } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';

const Rotor = ({ radius, size }) => {
  const rotor = useRef();
  const rotation = [0,0,0];

  useFrame(() => {
    if (rotor.current) {
      rotor.current.rotation.x += 0.0321;
      rotor.current.rotation.y += 0.00201;
      rotor.current.rotation.z += 0.0797;
    }
  });

  const ringRadius = radius + (size/2);
  const theta = 2 * Math.PI / 3;


  return (
    <group ref={rotor} rotation={rotation}>
      <mesh>
        <torusGeometry args={[ringRadius, 1, 8, 32]} />
        <meshBasicMaterial color="#999999" wireframe />
      </mesh>
      <mesh position={[ringRadius,0,0]}>
        <sphereGeometry args={[size, 32, 32]}/>
        <meshBasicMaterial color="#FF00FF" wireframe />
      </mesh>
      <mesh position={[Math.cos( theta) * ringRadius, Math.sin( theta) * ringRadius, 0]}>
        <sphereGeometry args={[size, 32, 32]}/>
        <meshBasicMaterial color="#00FFFF" wireframe />
      </mesh>
      <mesh position={[Math.cos( 2*theta) * ringRadius, Math.sin( 2*theta) * ringRadius, 0]}>
        <sphereGeometry args={[size, 32, 32]}/>
        <meshBasicMaterial color="#FFFF00" wireframe />
      </mesh>
    </group>
  );
};

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={10} />
      <spotLight
        castShadow
        intensity={0.2}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

export default function SingleRotor() {
  return (
    <Canvas shadowMap style={{ backgroundColor: '#000021' }} orthographic camera={{ zoom: 5, position: [10, 20, 50] }} >
      <OrbitControls />
      <Lights />
      <axesHelper args={[75]}/>
      <Rotor radius={50} size={3} />
    </Canvas>
  );
}
