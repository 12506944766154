/*

  File: layouts/sbs+image_text
  Kind: Component (Layout)
  Description: Side by side text and image

*/

import * as React from 'react';
import Image from '../markdown/image.jsx';

//Components

const SbsImageText = ({ image, title, copyright, span, side, children }) => {
  const Cols = 8;

  const ColSpan = [
    '',
    'md:col-span-1',
    'md:col-span-2',
    'md:col-span-3',
    'md:col-span-4',
    'md:col-span-5',
    'md:col-span-6',
    'md:col-span-7',
    'md:col-span-8'
  ]

  // console.log('PROJECT_IMAGE:', image);

  if (side === 'left') {
    return (
      <div className={`grid grid-cols-1 md:grid-cols-8 grid-rows-1 gap-x-8`}>
        <div className={`${ColSpan[span]}`}>
          <Image img={image} title={title} copyright={copyright} />
        </div>
        <div className={`${ColSpan[Cols - span]} mt-6`}>{children}</div>
      </div>
    );
  } else {
    return (
      <div className={`grid grid-cols-1 md:grid-cols-8 grid-rows-1 gap-x-8`}>
        <div className={`${ColSpan[Cols - span]} mt-6`}>{children}</div>
        <div className={`${ColSpan[span]}`}>
          <Image img={image} title={title} copyright={copyright} />
        </div>
      </div>
    );
  }
};

export default SbsImageText;
