/*

  File: tube.jsx
  Kind: ThreeJS canvas
  Description: Example of a single rotor in action, with dat gui controls

*/

import React, { useRef, useEffect, useState } from 'react';

import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OrbitControls, PerspectiveCamera, Effects, Text, Billboard } from '@react-three/drei';

import { Canvas, extend, useFrame, useResource } from 'react-three-fiber';

extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass });

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={10} />
      <spotLight
        castShadow
        intensity={0.2}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

const Markers = ({ visible }) => {
  //Show a cube, a sphere, pyramid and cylinder at cardinal positions

  return (
    <group>
      <mesh position={[0, 100, 0]} visible={visible}>
        <boxBufferGeometry args={[10, 10, 10]} />
        <meshBasicMaterial color={0xfe0110} wireframe />
      </mesh>
      <mesh position={[100, 0, 0]} visible={visible}>
        <sphereBufferGeometry args={[10, 16, 16]} />
        <meshBasicMaterial color={0x01fe10} wireframe />
      </mesh>
      <mesh position={[0, -100, 0]} visible={visible}>
        <coneBufferGeometry args={[10, 20, 4]} />
        <meshBasicMaterial color={0x0189fe} wireframe />
      </mesh>
      <mesh position={[-100, 0, 0]} visible={visible}>
        <cylinderBufferGeometry args={[10, 10, 10]} />
        <meshBasicMaterial color={0xfd89fe} wireframe />
      </mesh>
      <mesh position={[0, 0, 100]} visible={visible}>
        <ringBufferGeometry args={[10, 12, 32]} />
        <meshBasicMaterial color={0xfede10} wireframe />
      </mesh>
      <mesh position={[0, 0, -100]} visible={visible}>
        <ringBufferGeometry args={[10, 12, 32]} />
        <meshBasicMaterial color={0xfe1001} wireframe />
      </mesh>
    </group>
  );
};

const keyMap = {
  49: 'north',
  50: 'east',
  51: 'south',
  52: 'west',
  53: 'up',
  54: 'down',
};

const headHeight = 5;

const halfPI = Math.PI / 2;

const viewTarget = {
  north: { x: halfPI, y:0, z:0},
  east: { x: 0, y: -halfPI, z: -halfPI },
  south: { x: halfPI, y: Math.PI, z: 0 },
  west: { x: halfPI, y: halfPI, z: 0},
  up: {x: 0, y: 0, z: halfPI},
  down: {x: 0, y: 0, z:-halfPI},
};

const CameraControls = () => {

  const [direction, setDirection] = useState('north')
  const [target, setTarget] = useState(viewTarget[direction]);


  let save = false;

  const onKeyDown = (e) => {
    if (keyMap[e.keyCode]) {
      setDirection(keyMap[e.keyCode]);
      setTarget(viewTarget[direction]);
      console.log('point at:', direction, target);
    }
    if (e.keyCode === 83) {
      //Save Image
      console.log('saveImage');
        save = true;
    }
  };
  
  useEffect(()=> {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    }
  });

  useFrame(()=> {
    if (save) {
      const canvas = document.querySelector('canvas');
      const context = canvas.getContext('2d');
      const link = document.createElement('a');
      link.download = [direction,'png'].join('.');
      link.href = canvas.toDataURL();
      link.click();
      link.delete();  
      save = false;
    }
  })


  useFrame((state) => {
    // state.camera.lookAt(target[0], target[1], target[2]);
    state.camera.rotation.x = target.x;
    state.camera.rotation.y = target.y;
    state.camera.rotation.z = target.z;
    state.camera.updateProjectionMatrix();
  });
  return null;
};

const ConicalMountain = ({ height, radius, position }) => {
  const n_ = { l: 5, u: 17 };

  const n = Math.random() * (n_.u - n_.l) + n_.l;

  const theta = Math.random() * (Math.PI / n);

  const p = [position[0], position[1], height / 2 - 5];

  return (
    <mesh position={p} rotation={[Math.PI / 2, 0, 0]}>
      <coneBufferGeometry args={[radius, height, n, 1, true, theta]} />
      <meshBasicMaterial color={0x00ff00} wireframe />
    </mesh>
  );
};

const ConicalMountains = ({ radius, divisions, cone }) => {
  const th = (Math.PI * 2) / divisions;
  const dr = Math.tan(th) * radius * 1.5;

  console.log(dr);

  const mountains = Array.from({ length: divisions })
    .fill()
    .map((_, i) => {
      const r = dr + dr * (Math.random() - 0.5);

      const height = 25 + Math.random() * 200;

      const r_ = radius + (Math.random() - 0.5) * radius * 0.2;

      const p = [Math.cos(th * i) * r_, Math.sin(th * i) * r_, 0];

      // console.log(i, r, p);

      return <ConicalMountain key={i} id={i} height={height} radius={r} position={p} />;
    });

  const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff];

  const markers = Array.from({ length: divisions })
    .fill()
    .map((_, i) => {
      const p = [Math.cos(th * i) * radius, Math.sin(th * i) * radius, 350];

      const color = colors[i % colors.length];

      return (
          <Text
            key = {i}
            color={color}
            fontSize={25}
            anchorX="center"
            anchorY="middle"
            position={p}
            rotation={[Math.PI/2,0,0]}
          >
            {i}
          </Text>
      );
    });

  return (
    <group>
      {/* Guideline for main radius */}
      <mesh position={[0, 20, 0]} rotation={[0, 0, 0]} visible={false}>
        <ringBufferGeometry args={[radius, radius + 2, 32]} />
        <meshBasicMaterial color={0x00ff00} wireframe />
      </mesh>
      {mountains}
      {markers}
      {/* Plane */}
      <mesh position={[0, -5, 0]} rotation={[0, 0, 0]} visible={false}>
        <planeBufferGeometry args={[400, 400, 10, 10]} />
        <meshBasicMaterial color={0x016798} wireframe />
      </mesh>
    </group>
  );
};

const PostProcessing = () => {

  const [showEffects, setShowEffects] = useState(true);

  const onKeyDown = (e) => {
    if (e.keyCode === 88) {
      //x - toggle effects
      setShowEffects(!showEffects);
      console.log('show effects: ', showEffects);
    }
  }

  useEffect(()=> {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    }
  });


  if (showEffects) {
    return (
      <Effects>
        <unrealBloomPass attachArray="passes" args={[undefined, 1.5, 1, 0]} />
      </Effects>
    )
  } else {
    return null;
  }


}


const Terrain = () => {

  return (
    <>
      <Canvas shadowMap style={{ backgroundColor: '#000021' }} camera={{ position: [0, 0, headHeight], fov: 90 }}>
        {/* <axesHelper args={[75]} /> */}
        <CameraControls/>
        <Lights />
        {/* <axesHelper args={[150]} /> */}
        {/* <Markers visible /> */}
        <ConicalMountains radius={800} divisions={60} />
        <PostProcessing />
      </Canvas>
    </>
  );
};

export default Terrain;

/*
          
*/
