/*

  File: video/video.js
  Kind: Component
  Description: Video component, wrapper for YouTube and Vimeo, will return correct video based on the url provided

  Key Features: 
  * align: left : center

*/

import React from 'react'

//Components
import YouTube from "./youtube.js"
import Vimeo from "./vimeo.js"

const UrlTests = {
  youTube: /(youtube.com)|(youtu.be)/,
  vimeo: /(vimeo.com)/
};

const Video = (data) => {

  const {url, align, title} = data;

  //Test URL to work out if Vimeo or YouTube
  const type = Object.keys(UrlTests).reduce((a,c) => a || (UrlTests[c].test(url) && c), undefined);

  //console.log("url @ ", url, " is type ", type);

  if (!type) {
    //not recognised as a video
    return (
      <div className={`BadUrl-${url}`}/>
    )
  }

  const alignClass = align === 'center' ? 'items-center' : 'items-start';

  const Content = (type === 'youTube') ? <YouTube {...data}/> : <Vimeo {...data}/>

  const Caption = title ? (
    <div className="pt-4 text-saturn-100 font-medium font-serif">{title}</div>
  ) : ''
  
  return (
    <div className={`flex flex-col ${alignClass} m-4`}>
      <div className="">
        {Content}
      </div>
      {Caption}
    </div>
  )

}

export default Video
