/*

  File: tube.jsx
  Kind: ThreeJS canvas
  Description: Example of a single rotor in action, with dat gui controls

*/

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import * as THREE from 'three';
import { Canvas, extend, useFrame } from 'react-three-fiber';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OrbitControls, Effects } from '@react-three/drei';

extend({ RenderPass, UnrealBloomPass });

// Rotor as component
const Rotor = ({ position, rotation, spin, size, children, visible }) => {
  const rotor = useRef();

  useFrame(() => {
    rotor.current.rotation.x += (spin[0] / 3);
    rotor.current.rotation.y += (spin[1] / 3);
    rotor.current.rotation.z += (spin[2] / 2);
  });

  return (
    <group ref={rotor} rotation={rotation} position={position}>
      <mesh visible={false}>
        <torusGeometry args={[size, 0.5, 4, 32]} />
        <meshBasicMaterial color={0x666666} wireframe />
      </mesh>
      {children}
    </group>
  );
};

const Positions = {};

const Ball = ({ id, position, size, color, visible }) => {

  const ball = useRef();

  //const p = new THREE.Vector3();

  useFrame(() => {
    // console.log(Positions[id],ball.current);
    if (ball.current && Positions[id]) {
      const p = new THREE.Vector3();
      ball.current.getWorldPosition(p);
      Positions[id].unshift(p);
      Positions[id].splice(-1,1);
    }
  });

  return (
    <>
      <mesh ref={ball} position={position} visible={visible}>
        <sphereGeometry args={[size, 32, 32]} />
        <meshBasicMaterial color={color} wireframe/>
      </mesh>
    </>
  );
};

const Trail = ({ id, size, color, length, radial, segments }) => {
  
  const trail = useRef();

  Positions[id] = Array.from({length:length}, () =>  new THREE.Vector3());

  let d = 0;

  //Initial curve and tube
  const curve = new THREE.CatmullRomCurve3(Positions[id]);
  const tubeGeometry = new THREE.TubeGeometry(curve, length * segments, size, radial);

  const indices = tubeGeometry.getIndex();
  const vertices = tubeGeometry.getAttribute('position');

  //Update curve and tube
  useFrame(()=>{
    //new tube from updated points, then transfer vertices to current geometry
    const curve = new THREE.CatmullRomCurve3(Positions[id]);
    const tube = new THREE.TubeGeometry(curve, length * segments, size, radial);
    trail.current.geometry.setAttribute('position', tube.getAttribute('position'));
    trail.current.geometry.attributes.position.needsUpdate = true;
    trail.current.visible = (d > length);
    d++;
  });



  return (
    <mesh ref={trail}>
      <bufferGeometry attach="geometry">
        <bufferAttribute attach="index" array={indices.array} count={indices.array.length} itemSize={1} />
        <bufferAttribute
          attachObject={['attributes', 'position']}
          array={vertices.array}
          count={vertices.array.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <meshBasicMaterial color={color} wireframe />
    </mesh>
  );
};

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={10} />
      <spotLight
        castShadow
        intensity={0.2}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

const TubeTrails = ({ running }) => {

  return (
    <Canvas shadowMap style={{ backgroundColor: '#000021' }} camera={{ position: [10, 20, 80], fov: 80 }}>
      {/* <axesHelper args={[75]} /> */}
      <OrbitControls />
      <Lights />
      <Rotor id="r1" position={[0, 20, 0]} rotation={[0, 0, 0]} spin={[0.000123, 0.00067, 0.01]} size={50} visible>
        <Rotor id="r2" position={[0, 50, 0]} rotation={[0, 0, 0]} spin={[0.01, -0.0001, 0.0775]} size={40} visible>
          <Rotor id="r3" position={[40, 0, 0]} rotation={[0, 0, 0]} spin={[-0.021, 0.075, 0.1275]} size={30} visible>
            <Rotor
              id="r4"
              position={[Math.cos(60) * 30, Math.sin(60) * 30, 0]}
              rotation={[0, 0, 0]}
              spin={[-0.001, 0.175, 0.5275]}
              size={20}
              visible
            >
              <Rotor
                id="r5"
                position={[20, 0, 0]}
                rotation={[0, 0, 0]}
                spin={[-0.021, 0.075, 0.1275]}
                size={15}
                visible
              >
                <Ball
                  id="r5b1"
                  position={[Math.cos(42) * 15, Math.sin(42) * 15, 0]}
                  size={1}
                  color={0x0089FE}
                  visible
                />
                <Ball
                  id="r5b2"
                  position={[Math.cos(137) * 15, Math.sin(137) * 15, 0]}
                  size={1}
                  color={0xF08918}
                  visible
                />
                <Ball
                  id="r5b3"
                  position={[Math.cos(221) * 15, Math.sin(221) * 15, 0]}
                  size={1}
                  color={0xFF190A}
                  visible
                />
                <Rotor id="r3" position={[Math.cos(221) * 15, Math.sin(221) * 15, 0]} rotation={[0, 0, 0]} spin={[-0.00021, 0.000075, 1.4275]} size={5} visible>
                <Ball
                  id="r6b1"
                  position={[5, 0, 0]}
                  size={1.5}
                  color={0x00FE01}
                  visible
                />
                </Rotor>
              </Rotor>
            </Rotor>
          </Rotor>
        </Rotor>
      </Rotor>
      <group>
        <Trail id="r5b1" size={1} length={50} segments={5} radial={6} color={0x0089FE} />
        <Trail id="r5b2" size={1} length={50} segments={5} radial={6} color={0xF08918} />
        <Trail id="r5b3" size={1} length={50} segments={5} radial={6} color={0xFF190A} />
        <Trail id="r6b1" size={1.5} length={80} segments={5} radial={6} color={0x00FE01} />
      </group>
      <Effects>
        <unrealBloomPass attachArray="passes" args={[undefined, 0.6, 0.3, 0]} />
      </Effects>
    </Canvas>
  );
};

TubeTrails.propTypes = {
  running: PropTypes.bool,
};

TubeTrails.defaultProps = {
  running: true,
};

export default TubeTrails;
