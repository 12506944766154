/*

  File: markdown/embedAnimation.jsx
  Kind: MDX Component
  Description: Show an animation (three, p5 etc) and if also passed in, show source code in files - basically a wrapper for 

*/

import React from 'react';
import PropTypes from 'prop-types';

//Components
import EmbedTabbed from '../content/animation/embedTabbed';
import EmbedSideBySide from '../content/animation/embedSideBySide';

const Embed = (props) => {
  
  if (props.animation) {
    if (props.arrangement === 'SIDE_BY_SIDE') {
      return (
        <EmbedSideBySide {...props} />
      )
    } else {
      return (
        <EmbedTabbed {...props} />
      )
    }
  } else {
    return '';
  }
};

Embed.propTypes = {
  kind: PropTypes.string,
  title: PropTypes.string.isRequired,
  attribution: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignment: PropTypes.oneOf(['LEFT', 'CENTRE', 'RIGHT']),
  animation: PropTypes.object.isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  arrangement: PropTypes.oneOf(['SIDE_BY_SIDE', 'TABBED']),
};

Embed.defaultProps = {
  kind: 'threejs',
  alignment: 'CENTRE',
  arrangement: 'TABBED',
};

export default Embed;
