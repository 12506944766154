/*

  File: markdown/embed.jsx
  Kind: MDX Component
  Description: Wrap a component in an a div for alignment and sizing - add label (e.g. threejs), title, and any attribution required

*/

import * as React from 'react';
import PropTypes from 'prop-types';

//Components
import Title from '../common/title'
import Attribution from '../common/attribution'

const ParseDimensions = (w, h) => {
  const r = {
    style: {},
    className: ""
  };
  if (w && typeof(w) === 'number') {
    r.style.width = w;
  } else {
    r.className += w;
  }
  if (h && typeof(h) === 'number') {
    r.style.height = h;
  } else {
    r.className += h;
  }
  return r;
};

const Alignment = {
  LEFT: 'justify-start',
  CENTRE: 'justify-center',
  RIGHT: 'justify-end'
};

const Embed = (props) => {

  const { attribution, title, label, width, height, alignment, children } = props;


  //Return div etc if provided
  if (children) {
    const Dimensions = ParseDimensions(width, height);
    return (
      <div className={`flex flex-row ${Alignment[alignment]}`}>
        <div className="flex flex-col my-4 space-y-2">
          <div id="embedContainer" className="font-code font-light text-saturn-700 text-xs">threejs:</div>
          <div className={Dimensions.className} style={Dimensions.style}>
            {children}
          </div>
          <Title text={title}/>
          <Attribution text={attribution} label={label}/>
        </div>
      </div>
    )
  } else {
    return '';
  }
};

Embed.propTypes = {
  title: PropTypes.string.isRequired,
  attribution: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  alignment: PropTypes.oneOf(['LEFT', 'CENTRE', 'RIGHT'])
};

Embed.defaultProps = {
  alignment: 'CENTRE'
};


export default Embed;
