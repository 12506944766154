// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colophon-js": () => import("./../../../src/pages/colophon.js" /* webpackChunkName: "component---src-pages-colophon-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-images-js": () => import("./../../../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-refs-js": () => import("./../../../src/pages/refs.js" /* webpackChunkName: "component---src-pages-refs-js" */),
  "component---src-pages-see-me-js": () => import("./../../../src/pages/see-me.js" /* webpackChunkName: "component---src-pages-see-me-js" */),
  "component---src-pages-test-card-js": () => import("./../../../src/pages/test-card.js" /* webpackChunkName: "component---src-pages-test-card-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/blog/posts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */)
}

