/*

  File: rotorWithAnimatedObject.jsx
  Kind: ThreeJS canvas
  Description: Example of a nested rotors, with a an instanced object of spheres in a grid which are animated randomly

*/

import React, { useRef } from 'react';
// import PropTypes from 'prop-types';

import * as THREE from 'three';

import { Canvas, extend, useFrame } from 'react-three-fiber';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OrbitControls, Effects } from '@react-three/drei';


extend({ UnrealBloomPass });

// Rotor as component
const Rotor = ({ position, rotation, spin, size, children, visible }) => {
  const rotor = useRef();

  useFrame(() => {
    rotor.current.rotation.x += spin[0] / 3;
    rotor.current.rotation.y += spin[1] / 3;
    rotor.current.rotation.z += spin[2] / 2;
  });

  return (
    <group ref={rotor} rotation={rotation} position={position}>
      <mesh visible={false}>
        <torusGeometry args={[size, 0.5, 4, 32]} />
        <meshBasicMaterial color={0x666666} wireframe />
      </mesh>
      {children}
    </group>
  );
};

const SphereGrid = ({ position, rotation, number, size, spacing, color }) => {
  const sgRef = useRef();
  const n = number * number;
  let first = true;
  const tempObject = new THREE.Object3D();
  const tempColor = new THREE.Color();
  const rot = (Math.random() * 0.178) + 0.012

  const sphereState = Array.from({length: n}).fill().map(()=>true);

  //Animation - and set position on first pass
  useFrame(() => {

    if (first) {
      for (let i=0; i<n; i++) {
        const x = i / number;
        const y = i % number;
        tempObject.position.set(x * spacing, y * spacing, 0);
        //tempObject.scale.set(1);
        tempObject.updateMatrix();
        sgRef.current.setMatrixAt(i, tempObject.matrix);
      }
      sgRef.current.instanceMatrix.needsUpdate = true;
      first = false;
    }

     sgRef.current.rotation.z += rot;

      const i = Math.floor(Math.random() * n);
      sphereState[i] = !sphereState[i];
      const tcolor = sphereState[i] ? color : 0x000021
      tempColor.setHex(tcolor);
      sgRef.current.setColorAt(i, tempColor);
      sgRef.current.instanceColor.needsUpdate = true;



  });

  return (
    <instancedMesh ref={sgRef} args={[null, null, n]} rotation={rotation} position={position}>
      <sphereGeometry attach="geometry" args={[size, 32, 32]} />
      <meshBasicMaterial color={color} wireframe />
    </instancedMesh>
  );
};

function Lights() {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={1} />
      <spotLight
        castShadow
        intensity={0.1}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
}

const AnimatedRotor = () => {
  return (
    <Canvas shadowMap style={{ backgroundColor: '#000021' }} camera={{ position: [10, 20, 80], fov: 80 }}>
      {/* <axesHelper args={[75]} /> */}
      <OrbitControls />
      <Lights />
      <Rotor id="r1" position={[0, 20, 0]} rotation={[0, 0, 0]} spin={[0.000123, 0.00067, 0.01]} size={50} visible>
        <SphereGrid position={[Math.cos(0) * 50, Math.sin(0) * 50, 0]} rotation={[90, 0, 0]} color={0xFF190A} number={7} size={3} spacing={10} />
        <Rotor id="r2" position={[0, 50, 0]} rotation={[0, 0, 0]} spin={[0.01, -0.0001, 0.0775]} size={40} visible>
        <SphereGrid position={[Math.cos(30) * 40, Math.sin(30) * 40, 0]} rotation={[90, 0, 0]} color={0x00FE01} number={3} size={5} spacing={15} />
          <Rotor id="r3" position={[40, 0, 0]} rotation={[0, 0, 0]} spin={[-0.021, 0.075, 0.1275]} size={30} visible>
            <SphereGrid position={[Math.cos(0) * 30, Math.sin(0) * 30, 0]} rotation={[90, 0, 0]} color={0x0089fe} number={4} size={2} spacing={8} />
          </Rotor>
        </Rotor>
      </Rotor>
      <Effects>
        <unrealBloomPass attachArray="passes" args={[undefined, 2.1, 0.1, 0.1]} />
      </Effects>
    </Canvas>
    
  );
};

export default AnimatedRotor;