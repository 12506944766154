import SingleRotor from "../../../../content/blog/20210316_definingATestBed/threejs/singleRotor";
import SingleRotorNoRing from "../../../../content/blog/20210316_definingATestBed/threejs/singleRotorNoRing";
import NestedRotors from "../../../../content/blog/20210316_definingATestBed/threejs/nestedRotors";
import SphericalTrails from "../../../../content/blog/20210316_definingATestBed/threejs/sphericalTrails";
import TubeTrails from "../../../../content/blog/20210316_definingATestBed/threejs/tubeTrails";
import RotorPlane from "../../../../content/blog/20210316_definingATestBed/threejs/rotorWithPlane";
import RotorAnimated from "../../../../content/blog/20210316_definingATestBed/threejs/rotorWithAnimatedObject";
import * as React from 'react';
export default {
  SingleRotor,
  SingleRotorNoRing,
  NestedRotors,
  SphericalTrails,
  TubeTrails,
  RotorPlane,
  RotorAnimated,
  React
};