/*

  File: components/util/parseDimensions  Kind: Util
  Description: Return props in form width, height and return as style width / height if numeric, and classes if not (i.e. text)

*/

const ParseDimensions = (w, h) => {
  const r = {
    style: {},
    className: ""
  };
  if (w && typeof(w) === 'number') {
    r.style.width = w;
  } else {
    r.className += w;
  }
  if (h && typeof(h) === 'number') {
    r.style.height = h;
  } else {
    r.className += h;
  }
  return r;
};

export default ParseDimensions;