import TriangularHoles from "../../../../content/blog/20210414_makingHolesInMeshes/threejs/triangularHoles.jsx";
import DiamondHoles from "../../../../content/blog/20210414_makingHolesInMeshes/threejs/diamondHoles.jsx";
import SquareHoles from "../../../../content/blog/20210414_makingHolesInMeshes/threejs/squareHoles.jsx";
import Animation1 from "../../../../content/blog/20210414_makingHolesInMeshes/threejs/animation001.jsx";
import * as React from 'react';
export default {
  TriangularHoles,
  DiamondHoles,
  SquareHoles,
  Animation1,
  React
};