/*

  File: common/label.jsx
  Kind: Component
  Description: Label for site content (e.g. image, source code etc)

*/

import * as React from 'react';
import PropTypes from 'prop-types';


const Label = ({text}) => {
  //Set attribution if provided
  return (
    <span className="text-saturn-400">{text}</span>
  )
}

Label.propTypes = {
  text: PropTypes.string,
};

Label.defaultProps = {
  text: 'source:'
}

export default Label;