import React, { useRef, useEffect, useState, Suspense } from 'react';

//threejs
import * as THREE from 'three';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper';

//react-three-fiber
import { Canvas, useFrame } from 'react-three-fiber';
import { OrbitControls, Stats, useTexture, useHelper, Text } from '@react-three/drei';

//DatGui
import DatGui, { DatBoolean, DatColor, DatNumber, DatFolder } from 'react-dat-gui';
import { RGBA_ASTC_10x5_Format } from 'three';

const MakeDividedPlane = (props) => {
  const { width, height } = props;

  //construct vertices - use a unit size of 1 - these are always the same, we calculate indices based on hole style / shape
  const nv = (width + 1) * (height + 1);
  const vertices = new Float32Array(nv * 3);
  const uvs = new Float32Array(nv * 2);

  for (let i = 0; i < nv; i++) {
    const i_ = i * 3;
    vertices[i_] = i % (width + 1);
    vertices[i_ + 1] = Math.floor(i / (width + 1));
    vertices[i_ + 2] = 0;
    // console.log('VRT:',i,'BASE:', i_, 'VTX:',vertices[i_], vertices[i_ + 1], vertices[i_ + 2]);
    const i__ = i * 2;
    uvs[i__] = vertices[i_] / width;
    uvs[i__ + 1] = vertices[i_ + 1] / height;
  }

  return {
    vertices: vertices,
    uvs: uvs,
  };
};

const MakeMesh = (props) => {
  const { scale, color, indices, rotation, position, vertices, uvs } = props;

  const texture = useTexture('/threejs/textures/PlaneTexture.png');

  const material = color ? (
    <meshStandardMaterial color={color} side={THREE.DoubleSide} />
  ) : (
    <meshStandardMaterial map={texture} attach="material" side={THREE.DoubleSide} />
  );

  console.log('mesh', vertices);

  return (
    <mesh scale={scale} rotation={rotation} position={position}>
      <bufferGeometry attach="geometry">
        <bufferAttribute attach="index" array={indices} count={indices.length} itemSize={1} />
        <bufferAttribute
          attachObject={['attributes', 'position']}
          array={vertices}
          count={vertices.length / 3}
          itemSize={3}
        />
        <bufferAttribute attachObject={['attributes', 'uv']} array={uvs} count={uvs.length / 2} itemSize={2} />
      </bufferGeometry>
      {material}
    </mesh>
  );
};

const DiamondHoleMesh = (props) => {
  const { width, height } = props;

  //construct indices
  const nt = width * height;
  const indices = new Uint16Array(nt * 3);

  for (let i = 0; i < nt; i++) {
    const i_ = i * 3;
    const _i = i + Math.floor(i / width);

    const a = (_i % 2) * 2 + (Math.floor(i / width) % 2);

    switch (a) {
      case 0:
        indices[i_] = _i;
        indices[i_ + 1] = _i + 1;
        indices[i_ + 2] = _i + width + 1;
        break;
      case 1:
        indices[i_] = _i + 1;
        indices[i_ + 1] = _i + width + 1;
        indices[i_ + 2] = _i + width + 2;
        break;
      case 2:
        indices[i_] = _i;
        indices[i_ + 1] = _i + 1;
        indices[i_ + 2] = _i + width + 2;
        break;
      case 3:
        indices[i_] = _i;
        indices[i_ + 1] = _i + width + 1;
        indices[i_ + 2] = _i + width + 2;
        break;
    }

    // console.log('IDX:',i,'AlG:', a, 'BASE:', _i, 'VTX:',indices[i_], indices[i_ + 1], indices[i_ + 2]);
  }
  return indices;
};

const MeshWithDiamondHoles = (props) => {
  const { vertices, uvs } = MakeDividedPlane({ ...props });
  const indices = DiamondHoleMesh({ ...props });
  return <MakeMesh indices={indices} vertices={vertices} uvs={uvs} {...props} />;
};

const Lights = () => {
  return (
    <group>
      <pointLight intensity={0.3} />
      <ambientLight intensity={0.5} />
      <spotLight
        castShadow
        intensity={0.5}
        angle={Math.PI / 7}
        position={[150, 150, 250]}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </group>
  );
};

const TriangularHoles = (props) => {
  const statsRef = useRef();

  // const handleUpdate = (newData) => {
  //   setRotorStates(newData);
  // };

  return (
    <div ref={statsRef} id="StatsParent" className="h-full">
      <Canvas style={{ backgroundColor: '#4682b4' }} camera={{ fov: 80, position: [120, 60, 200] }}>
        {/* <Stats parent={statsRef}/> */}
        <OrbitControls />
        <Lights />
        {/* <axesHelper args={[75]} /> */}
        <Suspense fallback={null}>
          <group position={[0, -50, 0]}>
            <MeshWithDiamondHoles width={16} height={16} scale={[8, 8, 1]} />
            <MeshWithDiamondHoles
              width={16}
              height={16}
              scale={[8, 8, 1]}
              rotation={[0, -Math.PI / 2, 0]}
              color={0x9370db}
            />
            <MeshWithDiamondHoles
              position={[-50,160,10]}
              width={7}
              height={8}
              scale={[8, 8, 1]}
              rotation={[0, -Math.PI / 2, 0]}
              color={0xf0f8ff}
            />
          </group>
        </Suspense>
      </Canvas>
      <div id="triangular-holes-gui" style={{ position: 'absolute', top: 0, left: 0 }}>
        {/* <DatGui data={rotorStates} onUpdate={handleUpdate}>
          <DatFolder title="system1" closed={false}>
            <DatNumber path="system1.spin.x" label="spin.x" min={-1} max={1} step={0.001} />
            <DatNumber path="system1.spin.y" label="spin.y" min={-1} max={1} step={0.001} />
            <DatNumber path="system1.spin.z" label="spin.z" min={-1} max={1} step={0.001} />
            <DatBoolean path="system1.visible.rotor" label="visible.rotor"/>
          </DatFolder>
        </DatGui> */}
      </div>
    </div>
  );
};

export default TriangularHoles;
