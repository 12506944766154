/*

  File: markdown/codeBlock.jsx
  Kind: Component
  Description: Render source code, that has been loaded as a node by gatsby - this is designed to be a subcomponent in other items where attribution etc is rendered

*/

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

//Components

const CodeBlock = ({ path, lineNumbers, width, height }) => {

  const data = useStaticQuery(
    graphql`
      query {
        allSourceCode {
          nodes {
            code
            language
            path
          }
        }
      }
    `
  );

  const Sources = data.allSourceCode.nodes;

  const SourceCode = Sources.find((f) => f.path === path);

  const Dimensions = { width: width, height: height};

  if (SourceCode) {
    const { language, code } = SourceCode;
    const LineNumbers = lineNumbers ? 'line-numbers' : 'no-line-numbers';
    return (
      <div className="overflow-scroll" style={Dimensions}>
        <pre>
          <code className={`language-${language} ${LineNumbers}`}>{code}</code>
        </pre>
      </div>
    );
  } else {
    return '';
  }
};

CodeBlock.propTypes = {
  path: PropTypes.string.isRequired,
  lineNumbers: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

CodeBlock.defaultProps = {
  lineNumbers: true,
};

export default CodeBlock;