import { wrapRootElement as wrap } from './root-wrapper';

import "@fortawesome/fontawesome-svg-core/styles.css"
//Fira codeweights - TODO: optimise to only required ones
import "@fontsource/fira-code/300.css"
import "@fontsource/fira-code/400.css"
import "@fontsource/fira-code/500.css"
import "@fontsource/fira-code/600.css"
import "@fontsource/fira-code/700.css"
//Plex serif - TODO: pick some weights
import "@fontsource/ibm-plex-sans/200.css"
import "@fontsource/ibm-plex-serif/200.css"         //Body
import "@fontsource/ibm-plex-serif/300.css"         //Titles (big)
import "@fontsource/ibm-plex-serif/400.css"         //Titles (little)
import "@fontsource/ibm-plex-serif/600.css"         //Highlighted text
import './src/styles/global.css'
import './src/styles/markdown.css'
import './src/styles/datgui.css'
import './src/styles/prism-astronomyDomine.css'

import "katex/dist/katex.min.css"


// require('katex/dist/katex.min.css')

export const wrapRootElement = wrap;
