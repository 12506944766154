/*

  File: imageGallery.js
  Kind: Component
  Description: Image gallery as responsive grid, that can be used in mark down by supplying an array of images

  Attributes:

  images - array of childImageSharp.fluid images
  captions - array of mayching captions(optional)
  cols - number of cols

*/

import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";




// markup
const ImageGallery = ({ images, cols, caption }) => {
  //console.log('gall', images);

  
  // const gridCols = [
  //   '',
  //   'grid-cols-1',
  //   'grid-cols-2',
  //   'grid-cols-3',
  //   'grid-cols-4',
  //   'grid-cols-5',
  //   'grid-cols-6',
  //   'grid-cols-7',
  //   'grid-cols-8',
  //   'grid-cols-9',
  //   'grid-cols-10',
  //   'grid-cols-11',
  //   'grid-cols-12',
  // ]

  const gridColsMd = [
    '',
    'md:grid-cols-1',
    'md:grid-cols-2',
    'md:grid-cols-3',
    'md:grid-cols-4',
    'md:grid-cols-5',
    'md:grid-cols-6',
    'md:grid-cols-7',
    'md:grid-cols-8',
    'md:grid-cols-9',
    'md:grid-cols-10',
    'md:grid-cols-11',
    'md:grid-cols-12',
  ]

  const Cells = images.filter((f)=>{
    return f.image && f.image.gatsbyImageData;
  }).map((m) => {
    const { id, image, caption } = m;
    const Caption = (caption && <div className="my-2 font-serif font-normal text-neptune">{caption}</div>) || '';
    return (
      <div key={id} className="flex flex-col justify-center">
        <div>
          <GatsbyImage image={image.gatsbyImageData} alt={caption}/>
        </div>
        {Caption}
      </div>
    );
  });

  const Caption =
    (caption && (
      <div className="font-serif font-semibold font-lg mb-12 text-saturn">{caption}</div>
    )) ||
    '';

  return (
    <div className="flex flex-col">
      <div className={`grid grid-cols-1 mt-8 mb-4 ${gridColsMd[cols || 4]} gap-8`}>{Cells}</div>
      {Caption}
    </div>
  );
};

export default ImageGallery;
