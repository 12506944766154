import { MDXProvider } from '@mdx-js/react';
import React from 'react';

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Image from "./src/components/markdown/image"
import ImageGallery from "./src/components/ImageGallery"
import Video from "./src/components/video/video"
import EmbedCode from "./src/components/markdown/embedCode"
import Embed from './src/components/markdown/embedComponent'
import EmbedAnimation from './src/components/markdown/embedAnimation'

import SbsImageText from './src/components/layouts/sbs_image_text'



//Short shortcodes and a wrapper with a md class tag for css selection
const components = {
  Link,
  GatsbyImage,
  Image, 
  ImageGallery,
  Video,
  EmbedCode,
  Embed,
  EmbedAnimation,
  SbsImageText,
  wrapper: props => (
    <div className="md" {...props}/>
  )
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);